<template>
  <v-footer height="auto" class="mt-12 pa-4 not-printable" :dark="theme.dark" style="padding-bottom: 90px !important;">
    <v-row dense justify="center" align="center">
      <v-col cols="12" class="text-center">
        <BidStream></BidStream>
        <agreement-check-dialog
          v-if="showAgreementCheck"
        ></agreement-check-dialog>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn text to="/terms">
          Terms
        </v-btn>
        <v-btn text to="/privacy">
          Privacy
        </v-btn>
        <v-btn text :href="`mailto:${support}`" v-if="!tournament">
          Contact
        </v-btn>
        <support-dialog v-else></support-dialog>
        <qr-dialog color="color3"></qr-dialog>
        <opt-in-sms></opt-in-sms>
      </v-col>
      <v-col class="text-center body-2 pb-0" cols="12" >
        © {{ new Date().getFullYear() }} VolleyballLife.com. All rights reserved.
      </v-col>
      <v-col class="text-center py-0" cols="12" style="font-size: x-small;">
        Powered by Mohawk Man Technologies Inc.
      </v-col>
      <v-col class="text-center py-0" cols="12" style="font-size: x-small;">
        Live Updates: {{liveUpdateStatus}}
      </v-col>
      <v-col cols="12" class="mt-8 text-center">
        <organization-create v-if="user"></organization-create>
      </v-col>
        <!-- indoor club wizard -->
      <!-- <v-col cols="12" class="mt-4">
        <indoor-club-wizard v-if="user"></indoor-club-wizard>
      </v-col> -->
      <v-col class="text-center" cols="12" v-if="user && user.pages.length">
        <v-btn @click="toggleView" color="color3 color3Text--text">
          <v-icon class=mr-3>fas fa-eye</v-icon>
          Switch to {{altView}} view
        </v-btn>
      </v-col>
      <v-col class="text-center" cols="12" v-if="user && user.id === 1">
        {{$vuetify.breakpoint.name}}
      </v-col>
      <v-col class="text-center" cols="12" v-if="user && user.id === 1 && !!tournament">
        <division-dropper
          :tournament="tournament"
        ></division-dropper>
      </v-col>
      <v-col cols="12" class="pa-0" v-if="false">
        <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX V3 SWITCHER XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->
        <div class="text-left">
          <v-btn color="grey" @click.stop="goV3" text x-small class="mr-n3 mb-n3">
            <v-icon x-small>fat fa-pi</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex'
import OrganizationCreate from '@/components/Organization/Create.vue'
const SupportDialog = () => import('@/components/SupportDialog')
const QrDialog = () => import('@/components/Utils/QRDialog')
const DivisionDropper = () => import('@/components/Tournament/Division/DivisionDropper.vue')
const OptInSms = () => import('@/components/Forms/OptInSms.vue')
const AgreementCheckDialog = () => import('@/components/Agreements/AgreementCheckDialog.vue')

export default {
  props: ['altView', 'liveUpdateStatus', 'toggleView', 'inIFrame'],
  computed: {
    ...mapGetters(['tournament', 'user', 'theme', 'publicView']),
    support () {
      return this.theme.email || 'Support@VolleyballLife.com'
    },
    showAgreementCheck () {
      const p = this.theme.props.find(f => f.startsWith('agreement-'))
      return !!p
    }
  },
  methods: {
    goV3 () {
      const l = window.location.href
      const ver = l.includes('?') ? '&v=3' : '?v=3'
      const n = l + ver
      console.log(n)
      window.location.href = n
    }
  },
  components: {
    SupportDialog,
    QrDialog,
    OrganizationCreate,
    DivisionDropper,
    OptInSms,
    AgreementCheckDialog
  }
}
</script>
